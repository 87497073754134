import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import femaleCofounderIcon from '../icons/female_founder_icon.svg'
import maleFounderIcon from '../icons/male_founder_icon.svg'
import startupIdeaIcon from '../icons/startup_idea_icon.svg'
import matchIcon from '../icons/matching-icon.svg'

const Section1 = ({}) => (
  <section className="container py-10 md:py-26 lg:py-24 xl2:py-40">
    <div className="text-sm text-gradient uppercase font-display font-bold">Matching</div>
    <h2 className="text-2xl lg:text-4xl leading-none font-display font-bold">Female Co-Founders Datenbank</h2>
    <hr className="bg-meteor-500 bg-gradient-l-mulberry-500 h-1 border-0 w-1/5 my-6" />
    <div className="md:flex justify-center">
      <div className="text-center my-12 md:mr-8 md:w-1/3 rounded-xl py-12 px-4 border border-mulberry-500 flex flex-col" data-sal="slide-down" data-sal-delay="100" data-sal-duration="500">
        <div className="rounded-full h-24 w-24 md:h-32 md:w-32 bg-meteor-500 bg-gradient-l-mulberry-500 mx-auto p-4 md:p-6 text-center mb-2 md:mb-6">
          <img src={femaleCofounderIcon} className="h-full mx-auto" alt="Female Co-Founder" />
        </div>
        <h3 className="font-display text-gradient text-2xl" style={{ paddingBottom: 0 }}>Female Co-Founder</h3>
        <div className="mb-4">ohne eigene Idee</div>
        <p className="font-light leading-relaxed flex-grow">
          Du bist bereit dich einer bestehenden Startup-Idee anzuschließen oder diese gemeinsam
          mit deinem potentiellen Co-Founder (w/m/d) zu entwickeln.
        </p>
        <div class="text-center pt-8">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSc_RBSJsFrBM2aBqad2pXNtl0EZ8jcjcTDjtMEUS6sffcB4tA/viewform" target="_blank" className="inline-block hover:opacity-75 px-6 py-2 rounded mt-4 text-white bg-meteor-500 bg-gradient-l-mulberry-500">
            In Datenbank eintragen
          </a>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <img src={matchIcon} className="w-40" />
      </div>
      <div className="text-center my-12 md:ml-8 md:w-1/3 rounded-xl py-12 px-4 border border-mulberry-500 flex flex-col" data-sal="slide-down" data-sal-delay="300" data-sal-duration="500">
        <div className="rounded-full h-24 w-24 md:h-32 md:w-32 bg-meteor-500 bg-gradient-l-mulberry-500 mx-auto p-4 md:p-6 text-center mb-2 md:mb-6">
          <img src={startupIdeaIcon} className="h-full mx-auto" alt="Female Co-Founder" />
        </div>
        <h3 className="font-display text-gradient text-2xl" style={{ paddingBottom: 0 }}>Founder (m/w/d)</h3>
        <div className="mb-4">mit eigener Idee</div>
        <p className="font-light leading-relaxed flex-grow">
          Du (w/m/d) hast bereits eine eigene Startup-Idee oder  gegründet und suchst nach einem oder mehreren
          Female-Co-Founder(s) für dich oder dein Gründungsteam.
        </p>

        <div class="text-center pt-8">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLScjdIp2DyxFWGO7dsgEB5qWCf0W7v3ydjaEVHOwmL446sxAFQ/viewform" target="_blank" className="inline-block hover:opacity-75 px-6 py-2 rounded mt-4 text-white bg-meteor-500 bg-gradient-l-mulberry-500">
            In Datenbank eintragen
          </a>
        </div>
      </div>
    </div>
  </section>
)

Section1.propTypes = {
}

Section1.defaultProps = {
}

export default Section1
