import React from "react"

import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Header from "../components/join/header"
import Section1 from "../components/join/section1"
import Section2 from "../components/join/section2"
import SEO from "../components/seo"

const JoinPage = () => (
  <Layout>
    <SEO title="Join" />
    <Navigation isAbsolute={false} currentPage="join" inverted />
    <Header />
    <Section1 />
    <Section2 />
  </Layout>
)

export default JoinPage
