import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import mobilePhoto from '../photos/join_mobile_header.png'
import photo from '../photos/join_header.png'

const Header = ({}) => (
  <div className="relative mb-16 bg-pampas" style={{ zIndex: 1 }}>
    <div className="rotated-bg absolute bg-pampas">
    </div>

    <div className="md:hidden">
      <img src={mobilePhoto} />
    </div>

    <div className="fc-header-wrapper cf hidden md:block">
      <div className="fc-header cf" style={{ backgroundImage: `url(${photo})` }}></div>
    </div>

    <div className="container py-8 md:py-12 xl:py-24 xl2:py-42">
      <div className="md:w-7/12">
        <div className="lg:text-lg tracking-wider text-gray-500 uppercase font-display font-bold mb-1 md:mb-4">Join</div>
        <h1 className="text-4xl lg:text-6xl leading-none font-display font-bold text-gradient">Co-Founder<br />Matching</h1>
        <hr className="bg-meteor-500 bg-gradient-l-mulberry-500 h-1 border-0 w-1/5 my-6" />
        <p className="font-light leading-relaxed">
          Dich hat der Gründungswille gepackt und du bist auf der Suche nach einem Team und einer Startup-Idee,
          der du dich anschließen kannst? Dann trage dich in unsere Datenbank ein und erhalte passende
          Co-Founding-Angebote:
        </p>
      </div>
    </div>
  </div>
)

Header.propTypes = {
}

Header.defaultProps = {
}

export default Header
