import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import firstIcon from '../icons/fragebogen_icon.svg'
import secondIcon from '../icons/angebot_icon.svg'
import thirdIcon from '../icons/coaching_icon.svg'

const Section2 = ({}) => (
  <div className="relative mb-16">
    <div className="rotated-bg absolute bg-vanilla-ice"></div>
    <div className="bg-vanilla-ice h-16 absolute w-full" style={ { bottom: "-4rem", zIndex: -1 } }></div>
    <section className="container py-10 md:pt-26 md:pb-12 lg:pt-24 xl2:pt-40">
      <div className="text-sm text-gradient uppercase font-display font-bold">Matchmaking</div>
      <h2 className="text-2xl lg:text-4xl leading-none font-display font-bold">Wie läuft es ab?</h2>
      <hr className="bg-meteor-500 bg-gradient-l-mulberry-500 h-1 border-0 w-1/5 my-6" />
      <div className="lg:flex lg:pt-8">
        <div className="lg:w-4/12 lg:mr-20 mt-16 lg:mt-0 text-center">
          <div className="rounded-full bg-white bg-opacity-50 w-32 h-32 flex mx-auto items-center mb-6">
            <img src={firstIcon} alt="Vermittlung" className="h-16 mx-auto" />
          </div>
          <h3 className="text-xl lg:text-2xl leading-none font-bold mb-4 text-gradient">Datenbank</h3>
          <p className="font-light leading-relaxed">
            Über einen Fragebogen, den wir für potentielle female Co-Founders entwickelt haben, kannst du dich
            in unsere Datenbank eintragen lassen. Es geht dabei um deine Gründungsabsicht, Kompetenzen, Erfahrungen
            und vieles mehr. Deine Daten werden nicht veröffentlicht und nur mit deinem vorherigen Einverständnis an suchende
            Gründer*innen weitergegeben.
          </p>
        </div>
        <div className="lg:w-4/12 lg:mr-20 mt-16 lg:mt-0 text-center">
          <div className="rounded-full bg-white bg-opacity-50 w-32 h-32 flex mx-auto items-center mb-6">
            <img src={secondIcon} alt="Vermittlung" className="h-16 mx-auto" />
          </div>
          <h3 className="text-xl lg:text-2xl leading-none font-bold mb-4 text-gradient">Matching</h3>
          <p className="font-light leading-relaxed">
            Sobald wir ein Angebot für dich haben, melden wir uns bei dir und erklären dir kurz und knapp, worum
            es geht. Wenn dir gefällt, was du hörst, gibst du uns dein Einverständnis und wir leiten deine Kontaktdaten an
            die suchende Co-Founderin bzw. den suchenden Co-Founder weiter.
          </p>
        </div>
        <div className="lg:w-4/12 mt-16 lg:mt-0 text-center">
          <div className="rounded-full bg-white bg-opacity-50 w-32 h-32 flex mx-auto items-center mb-6">
            <img src={thirdIcon} alt="Vermittlung" className="h-16 mx-auto" />
          </div>
          <h3 className="text-xl lg:text-2xl leading-none font-bold mb-4 text-gradient">Coaching</h3>
          <p className="font-light leading-relaxed">
            Wenn es gut läuft und ihr eine Zusammenarbeitet startet, begleiten wir euch gern mit einem
            Coaching, das euch unterstützt, euch gezielt kennenzulernen und schnell ins
            produktive Arbeiten zu kommen.
          </p>
        </div>
      </div>
    </section>
  </div>
)

Section2.propTypes = {
}

Section2.defaultProps = {
}

export default Section2
